








































@import "@/style/variables";

.navigation-bar {
  background-color: white;
  height: calc(env(safe-area-inset-top) + 72px);
  width: 100%;
  z-index: 100;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);

  .navigation-bar-container {
    height: 72px;
    top: calc(env(safe-area-inset-top));
    position: relative;

    .title {
      max-height: 72px;
      position: absolute;
      text-transform: uppercase;
      letter-spacing: 3px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $secondary;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .left-buttons {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      margin-left: 20px;

      .buttons {
        margin-right: 5px;
        display: inline-block;
      }
    }

    .right-buttons {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      margin-right: 20px;

      .buttons {
        margin-left: 5px;
        display: inline-block;
      }
    }
  }
}
