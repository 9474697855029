





















































@import "@/style/mixins";

.widget-service {
  position: relative;

  .widget {
    padding: 24px;

    .icon-container {
      border-radius: 4px;
      background-color: $background-primary;

      .icon {
        width: 80px;
        height: 80px;
        padding: 12px;
        object-fit: contain;
      }
    }

    .service-img {
      width: 80px;
      height: 80px;
      border-radius: 4px;
      padding: 12px;
      object-fit: contain;
      background-color: $background-primary;
    }

    .detail {
      overflow: hidden;
      padding-left: 23px;

      .name {
        width: 100%;

        text-align: left;
      }

      .description {
        width: 100%;
        @include body();
      }

      .name,
      .description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .jda-btn {
      width: 97px;
      position: absolute;
      right: 16px;
      bottom: -18px;
      text-align: center;
      text-decoration: none;
      line-height: 48px;
    }
  }
}
