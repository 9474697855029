





























.pdf {
  width: 100%;
  height: 100%;

  &.hidden {
    opacity: 0;
  }

  &.shown {
    opacity: 1;
  }

  &.intro {
    transition: opacity 400ms;
  }

  &.outro {
    transition: opacity 400ms 300ms;
  }
}
