









































@import "@/style/variables";

.widget-dropdown {
  .animated-chevron {
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;

    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
  }

  .title-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 24px;
    border-top-left-radius: 8px 8px;
    border-top-right-radius: 8px 8px;
    background-color: $background-primary;
    cursor: pointer;

    .title {
      color: $secondary;
      text-align: left;
    }

    .animated-chevron {
      transform: rotate(0deg);
    }
  }

  .collapsed {
    .title-info {
      border-radius: 8px;
    }

    .animated-chevron {
      transform: rotate(-180deg);
    }
  }

  .card-body {
    padding: 16px;
  }
}
