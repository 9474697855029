





























































.services-catalog {
  height: 100%;
  display: flex;
  flex-flow: column;

  .service {
    padding-top: 40px;
    padding-bottom: 42px;
  }
}
