









































































@import "@/style/mixins";

.widget-activity {
  min-width: 230px;
  padding: 0;
  text-align: left;

  .widget {
    height: 72px;
    padding: 0 12px;
    position: relative;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);

    &.current-activity {
      border: 2px solid $primary;
    }

    .btn-widget {
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .content {
      flex-shrink: 1;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 400px;
      @include title-body();

      .hour {
        @include body3();
        font-weight: normal;

        .icon-time {
          width: 14px;
          height: 14px;
        }
      }
    }

    .icon-type {
      width: 40px;
      height: 40px;
      margin-right: 12px;
      padding: 0 12px;
      border-radius: 8px;
      text-align: center;

      .icon {
        width: 16px;
        height: 100%;
        opacity: 0.5;
        object-fit: contain;
      }

      &.morning {
        background-color: $activity-morning;
      }

      &.afternoon {
        background-color: $activity-afternoon;
      }
    }
  }
}
