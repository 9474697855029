

































.column {
  width: 49.8%;
  height: 100%;
  padding: 2.63%;
  display: inline-block;
  vertical-align: top;

  .last-item {
    margin-top: 10.76%;
  }

  .full-height-item {
    height: 100%;
  }
}
