















































.home {
  height: 100%;
  display: flex;
  flex-flow: column;

  .content {
    height: 100%;
    padding: 0 25px;

    .widget-container {
      width: 100%;
      max-width: 600px;
    }
  }
}
