



























.map {
  height: 100%;
  width: 100%;
  padding: 40px;

  .pdf-container {
    height: 100%;
    overflow: auto;
  }
}
