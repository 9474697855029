










































@import "../../style/variables";

.card-weather {
  .weather-icon {
    width: 20.56%;
    vertical-align: middle;
  }

  .weather-content {
    width: 75%;
    padding-left: 4%;
    font-size: 275%;
    color: $secondary;
    vertical-align: middle;
    display: inline-block;

    .capitalize {
      text-transform: capitalize;
    }

    .min-temp {
      opacity: 0.5;
    }

    p {
      margin-bottom: 0;
    }
  }

  .info-unavailable {
    margin: 9% 0;
    text-align: center;
    font-size: 250%;
    font-weight: 600;
    font-family: "Lobster", sans-serif;
    color: $secondary;
  }
}
