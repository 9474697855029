@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/src/index.scss";
@import "fonts";
@import "variables";
@import "mixins";
@import "form";
@import "buttons";
@import "loader";

html,
body,
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#app > div {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: $text-body;
  background-color: $textfield-bg;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
  text-align: center;
}

h1 {
  @include title();
}

h2 {
  @include sub-title();
}

h3 {
  @include title-h3();
}

h4 {
  @include title-section();
}

h5 {
  @include title-body();
}

h6 {
  @include title-body2();
}

a,
.link {
  text-decoration: underline;
  color: $text-body;
}

input,
button,
select {
  &:hover,
  &:focus,
  &:active {
    outline: 0;
  }
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.home-view {
  background-image: url("~@/assets/images/background.png");
  background-image: -webkit-image-set(url("~@/assets/images/background@2x.png") 2x, url("~@/assets/images/background@3x.png") 3x);
  background-repeat: no-repeat;
  background-size: cover;
}

.navigation-bar + .app-view {
  min-height: 0;
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  background-color: $textfield-bg;
}

.app-view > .content {
  height: 100%;
}

.list-group-item {
  border-radius: 0;
  border-color: transparent;
  padding: 0;
}

.border-horizontal {
  width: 100%;
  height: 5px;
  background-image: url("~@/assets/images/separator-horizontal.png");
  background-image: -webkit-image-set(
    url("~@/assets/images/separator-horizontal@2x.png") 2x,
    url("~@/assets/images/separator-horizontal@3x.png") 3x
  );
  background-image: image-set(
    url("~@/assets/images/separator-horizontal@2x.png") 2x,
    url("~@/assets/images/separator-horizontal@3x.png") 3x
  );
  background-repeat: repeat-x;
}

.border-vertical {
  width: 5px;
  height: 100%;
  background-image: url("~@/assets/images/separator-vertical.png");
  background-image: -webkit-image-set(
    url("~@/assets/images/separator-vertical@2x.png") 2x,
    url("~@/assets/images/separator-vertical@3x.png") 3x
  );
  background-image: image-set(url("~@/assets/images/separator-vertical@2x.png") 2x, url("~@/assets/images/separator-vertical@3x.png") 3x);
  background-repeat: repeat-y;
}

.loader {
  border: 5px solid white;
  border-radius: 50%;
  border-top: 5px solid $secondary;
  width: 25px;
  height: 25px;
  margin: auto;
  -webkit-animation: spin 0.8s linear infinite; /* Safari */
  animation: spin 0.8s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
