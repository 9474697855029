



































@import "@/style/variables";

.jda-btn {
  padding-left: 16px;
  padding-right: 16px;

  .arrow {
    object-fit: contain;
  }

  .title {
    &.left {
      padding-left: 10px;
    }

    &.right {
      padding-right: 10px;
    }

    @media (max-width: $sm) {
      display: none;
    }
  }
}
