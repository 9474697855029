


























































































































@import "@/style/mixins";

.surveys {
  height: 100%;
  display: flex;
  flex-flow: column;

  .survey-container {
    padding: 40px 22.5px 40px 22.5px;

    .title {
      margin-bottom: 43px;
      padding: 0 17.5px;
    }

    .survey-section-title {
      @include title-secondary-style();
      text-align: left;
      padding: 0 17.5px;
    }

    .widget-container {
      width: 100%;
      max-width: 540px;
      padding: 20px 17.5px 20px 17.5px;

      .widget {
        padding: 15px;

        .name {
          color: $secondary;
          font-weight: 600;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .border-horizontal {
      margin-bottom: 57px;
    }
  }
}
