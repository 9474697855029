






















.team-day {
  .block {
    padding-bottom: 19px;
  }
}
