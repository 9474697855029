





































@import "@/style/mixins";

.information {
  height: 100%;

  .info-container {
    width: 100%;
    max-width: 500px;
    margin: 20px;

    .title {
      @include title-secondary-style();
      margin-bottom: 40px;
    }

    .widget {
      padding: 20px;

      .info {
        font-weight: normal;
        color: $text-body;
      }
    }
  }
}
