


















































































@import "@/style/mixins";

.checkbox-form {
  width: 100%;
  max-width: 510px;
  margin: 0 auto;

  .form-type {
    color: $text-body;
    font-style: italic;
    font-weight: normal;
  }

  .widget {
    padding: 27.5px 32px;
    margin-top: 50px;

    .checkbox-content {
      padding: 17.5px 0;
      max-width: 510px;

      .name {
        @include title-h3();
        font-weight: normal;
        padding-right: 15px;
      }

      .checkbox {
        width: 20px;
        height: 20px;
        margin-right: 32px;
        border-radius: 4px;
        border: solid 1.5px $secondary;
        background-color: rgba($background-default, 0.5);
        position: relative;

        .checked {
          position: absolute;
          bottom: 1px;
          left: 2px;
        }
      }
    }
  }
}
