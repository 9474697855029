


















@import "@/style/mixins";

.number-form {
  width: 100%;
  max-width: 355px;
  margin: 0 auto;
  padding: 11px;

  .form-type {
    color: $text-body;
    font-style: italic;
    font-weight: normal;
  }

  .number {
    margin-top: 50px;
    width: 100%;
    @include title-h3();
    text-align: center;
    font-weight: normal;
    color: $text-body;
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    background-color: white;
  }

  ::placeholder {
    color: $disabled;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: $disabled;
  }

  ::-ms-input-placeholder {
    color: $disabled;
  }
}
