


































@import "@/style/variables";

.popup-alert {
  padding: 40px 24px 24px 24px;

  .title {
    margin-bottom: 40px;
  }

  .sub-title {
    color: $text-body;
    margin-bottom: 40px;
  }

  .btn-single {
    width: 100%;
    max-width: 435px;
  }

  .btn-left,
  .btn-right {
    height: 48px;
    width: 150px;
    margin: 16px;
  }
}
