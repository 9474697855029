































































@import "@/style/variables";

.generate-token-form {
  .title {
    margin-bottom: 24px;
  }

  .sub-title {
    color: $text-body;
    font-weight: normal;
  }

  .fields {
    padding-top: 48px;
  }
}
