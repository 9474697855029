







































@import "../../style/_variables.scss";

.screen-anecdote {
  .screen-content-wrapper {
    padding: 5%;
  }

  .date {
    font-size: 550%;
    font-family: "Lobster", sans-serif;
    font-weight: normal;
    text-align: left;
    text-transform: capitalize;
    margin: 2.79% 0;
  }

  .title {
    margin-top: 5%;
    font-size: 300%;
    font-weight: 600;
    color: $secondary;
  }

  .screen-card {
    padding: 2.81%;

    .content {
      margin: 0;
    }
  }
}
