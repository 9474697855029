
















































@import "@/style/variables";

.widget-weather {
  .widget-content {
    padding: 16px 24px;

    .icon {
      margin-bottom: 25px;
      object-fit: contain;

      &.icon-meteo {
        width: 121px;
      }

      &.icon-calendar {
        width: 85px;
      }
    }

    .calendar {
      text-align: center;
      position: relative;

      .date {
        width: 100%;
        position: absolute;
        top: 34px;
        font-size: 2.5rem;
      }
    }

    h5 {
      color: $text-body;
    }

    h6 {
      color: $secondary;
      font-weight: normal;
    }
  }
}
