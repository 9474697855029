































































































































































































































































































.screen-wrapper {
  width: 100%;
  height: 100%;
  font-family: "Helvetica", sans-serif;
  background: black;
}

.img-center {
  margin: auto;
  display: block;
}

.screen {
  position: relative;
  background: #f7f9f8;

  .screen-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}
