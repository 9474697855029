input.form-control {
  min-height: 48px;
  background-color: $textfield-bg;
  border: solid 1px rgba(45, 85, 75, 0.5);
  border-radius: 6px;
}

label {
  font-size: 1.5rem;
  padding: 0 8px;
}
