
















































.list-residence {
  height: 100%;
  padding: 50px;

  .list-residence-container {
    height: 100%;

    .widget {
      max-width: 550px;
      padding: 32px;

      .title {
        margin-bottom: 24px;
      }

      .empty {
        text-align: center;
        margin-bottom: 24px;
      }

      .btn-primary,
      .btn-danger,
      .btn-default {
        max-width: 500px;
        margin: 16px 0;
      }
    }
  }
}
