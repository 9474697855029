



























































@import "@/style/mixins";

.faq {
  height: 100%;
  padding: 20px;

  .widget-faq {
    margin: 20px 20px 32px 20px;

    .answer {
      @include body2();
    }
  }
}
