































































































.pdf-document {
  width: 100%;
  height: 100%;

  .pdf-block {
    height: 100%;
    position: relative;

    .pdf-container {
      height: 100%;
      padding: 40px;
      border-radius: 8px;
      overflow: auto;
      text-align: center;
      background-color: white;
    }

    .btn-add {
      bottom: 104px;
    }

    .btn-sub {
      bottom: 24px;
    }

    .btn-add,
    .btn-sub {
      width: 56px;
      height: 56px;
      position: absolute;
      right: 24px;
    }
  }
}
