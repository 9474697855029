

























.widget-team {
  .team {
    padding: 18px 24px 0 18px;
  }
}
