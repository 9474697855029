@import "variables";

@mixin title {
  font-size: 2rem; //32px
  font-weight: 600;
  color: $secondary;
}

@mixin sub-title {
  font-size: 1.5rem; //24px
  font-weight: 600;
  color: $secondary;
}

@mixin title-h3 {
  font-size: 1.375rem; // 22px
  font-weight: 600;
  color: $secondary;
}

@mixin title-section {
  font-size: 1.25rem; // 20px
  font-weight: 600;
  color: $secondary;
}

@mixin title-body {
  font-size: 1.125rem; // 18px
  color: $secondary;
  font-weight: 600;
}

@mixin title-body2 {
  font-size: 1rem; //16px
  color: $secondary;
  font-weight: 600;
}

@mixin body {
  font-size: 1.25rem; // 20px
  color: $text-body;
}

@mixin body2 {
  font-size: 1.125rem; // 18px
  color: $text-body;
}

@mixin body3 {
  font-size: 0.875rem; // 14px
  color: $text-body;
}

@mixin title-bold {
  font-weight: 600;
  color: $secondary;
}

@mixin title-secondary-style {
  font-family: "Lobster";
  font-weight: normal;
}
