


































@import "@/style/mixins";

.album-group {
  width: 310px;

  .content {
    width: 100%;
    height: 211px;
    object-fit: contain;
    position: relative;

    .first {
      width: 256px;
      height: 170px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .title {
    margin-top: 16px;
    @include title-bold();
  }

  .sub-title {
    margin-top: 8px;
    color: $text-body;
  }
}
