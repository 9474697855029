// screen size
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// colors
$primary: #469682;
$secondary: #2d554b;
$highlight: #f0e600;
$danger: #ad0e0e;
$text-body: #666666;
$textfield-bg: #f7f9f8;
$text-field-border: #96aaa5;
$activity-morning: #b2d8ce;
$activity-afternoon: #fff2bf;
$disabled: #b5b5b5;
$border: #e8e8e8;
$background-primary: #e3efec;
$background-default: #c7dfd9;
$background-danger: #fcd9d9;
$white: #ffffff;
$place-category-pink: #f59c9c;
$place-category-cyan: #54eaff;
$place-category-orange: #e49a54;
$place-category-red: #ff0000;
$place-category-magenta: #de5dde;
$place-category-blue: #272da1;
$place-category-aqua: #40cdaa;
$place-category-other: #54eaff;
$place-category-skyBlue: #0098ff;
$place-category-yellowGreen: #aec705;
$place-category-forestGreen: #008c42;
$place-category-gold: #ffcf34;
$place-category-deepPink: #d1038e;
$place-category-darkMagenta: #9b029b;
$place-category-darkOrange: #e97100;
$place-category-darkRed: #c41919;

$gray: #b5b5b5;
