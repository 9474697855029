




























































































.screen-album {
  .screen-content-wrapper {
    position: relative;

    .photo-container {
      position: absolute;

      &.solo {
        top: 5%;
        bottom: 5%;
        left: 12.5%;
        right: 12.5%;

        .screen-photo {
          padding: 0.55%;
        }
      }

      &.duo {
        &:nth-child(1) {
          top: 13.4%;
          right: 47.6%;
          bottom: 23.7%;
          left: 5%;
          transform: rotate(-7deg);
        }

        &:nth-child(2) {
          top: 30%;
          right: 5%;
          bottom: 13.4%;
          left: 50%;
          transform: rotate(5.4deg);
        }

        .screen-photo {
          padding: 0.9%;
        }
      }

      &.trio {
        &:nth-child(1) {
          top: 9.7%;
          right: 54.4%;
          bottom: 43.6%;
          left: 8.1%;
          transform: rotate(-3.2deg);
        }

        &:nth-child(2) {
          top: 46.7%;
          right: 31.9%;
          bottom: 8.8%;
          left: 29.8%;
        }

        &:nth-child(3) {
          top: 14.3%;
          right: 8.1%;
          bottom: 43.8%;
          left: 58.2%;
          transform: rotate(3deg);
        }

        .screen-photo {
          padding: 1.1%;
        }
      }

      &.quatuor {
        &:nth-child(1) {
          top: 38.4%;
          right: 65.1%;
          bottom: 21.8%;
          left: 3.3%;
          transform: rotate(-3.6deg);
        }

        &:nth-child(2) {
          top: 7.3%;
          right: 44.7%;
          bottom: 52.6%;
          left: 24.4%;
          transform: rotate(5.3deg);
        }

        &:nth-child(3) {
          top: 57.7%;
          right: 27.4%;
          bottom: 5.3%;
          left: 40.7%;
        }

        &:nth-child(4) {
          top: 23.8%;
          right: 4.5%;
          bottom: 35.9%;
          left: 63.5%;
          transform: rotate(-3.7deg);
        }

        .screen-photo {
          padding: 1%;
          border-radius: 16px;
        }
      }

      .screen-photo {
        width: 100%;
        height: 100%;
        border-radius: 22px;
        background: white;
        box-shadow: 0px 3px 11px 0px #00000014;
        object-fit: cover;
      }
    }
  }
}
