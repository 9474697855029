



























































































































@import "@/style/mixins";

.residence {
  height: 100%;
  display: flex;
  flex-flow: column;

  .residence-content {
    height: 100%;
    min-height: 0;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;

    .title {
      display: none;
    }

    .tab {
      width: 214px;
      height: 100%;
      z-index: 2;
      background-color: white;
      box-shadow: 0 5px 5px 5px rgba(rgb(31, 31, 31), 0.03);

      .btn-tab {
        width: 100%;
        min-width: 214px;
        padding: 16px 30px;
        @include sub-title();
        text-align: left;
        color: $secondary;
        background-color: white;

        &:hover {
          background-color: rgba($color: $secondary, $alpha: 0.3);
        }

        &.active,
        &:active {
          background-color: $secondary;
          color: white;
          font-weight: bold;
        }
      }
    }

    .content {
      min-width: 320px;
      height: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .block {
        width: 100%;
      }

      .timesheet {
        margin-top: 245px;
      }
    }

    @media (max-width: $md) {
      flex-flow: column;

      .title {
        width: 100%;
        text-align: left;
        padding: 16px 30px;
        display: inline-table;
        background-color: white;
      }

      .tab {
        width: 100%;
        height: auto;
        flex-flow: row;
      }

      .content {
        overflow-y: visible;
      }
    }
  }
}
