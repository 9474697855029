
























@import "@/style/variables";

.pdf-viewer {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-view {
    padding: 40px;

    @media (max-width: $sm), (max-width: $md) and (orientation: landscape) {
      padding: 20px;
    }
  }
}
