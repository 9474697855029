

















@import "@/style/mixins";

.internet-error {
  height: 100%;
  padding: 20px;
  min-height: 0;

  .sub-title {
    max-width: 415px;
    margin: 30px 0;
    text-align: center;
    @include body();
  }

  .jda-btn {
    width: 245px;
  }
}
