











































.header {
  padding: 32px 20px 20px 20px;

  .title {
    margin: 0 40px;
    font-family: "Lobster", sans-serif;
    font-weight: normal;
  }
}
