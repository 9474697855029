













































@import "@/style/mixins";

.popup-activity {
  padding-top: 16px;
  position: relative;

  .btn-close {
    position: absolute;
    top: 16px;
    left: 30px;

    .img-close {
      width: 24px;
      height: 24px;
    }
  }

  .title {
    margin: 0 56px 33px 56px;
  }

  .content {
    .img-activity {
      width: 100%;
      max-width: 335px;
      object-fit: contain;
      border-radius: 8px;
      padding: 15px;
    }

    .information-container {
      padding: 15px;

      @media (min-width: $md) {
        &.has-image {
          max-width: 335px;
        }
      }

      .description {
        @include body();
        margin-bottom: 15px;
      }

      .information {
        padding: 16px;
        border-radius: 8px;
        background-color: $textfield-bg;

        .information-title {
          text-align: left;
        }

        .border {
          width: 100%;
          height: 2px;
          border: solid 1px $border;
          margin-top: 3.5px;
          margin-bottom: 8.5px;
        }

        .information-content {
          @include body();
        }
      }
    }
  }
}
