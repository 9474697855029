












































@import "../../style/_variables.scss";

.menu-item {
  font-size: 250%;
  font-weight: 500;
  text-align: center;

  &.next-days {
    font-size: 162.5%;
  }

  > p {
    margin-bottom: 5%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .coming-soon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    font-size: 165%;
    font-weight: 600;
    font-family: "Lobster", sans-serif;
    color: $highlight;

    &.next-days {
      position: relative;
      margin: 0;
      transform: none;
    }
  }

  .content {
    display: block;
    line-height: normal;

    &:first-letter {
      text-transform: capitalize;
    }
  }
}
