


























@import "@/style/variables";

.jda-btn {
  padding-left: 16px;
  padding-right: 16px;

  &.btn-default {
    background-color: $background-primary;
  }

  .arrow {
    object-fit: contain;
  }

  .name {
    padding-left: 15px;
  }

  &.secondary {
    .name {
      color: white;
    }
  }

  @media (max-width: $sm) {
    .name {
      display: none;
    }
  }
}
