











































































@import "../../style/_variables.scss";

.screen-card.activity-card {
  display: inline-block;
  height: 74%;
  padding: 0.8%;
  margin: 1%;
  border: solid 2px white;

  p {
    margin: 0;
  }

  &.now {
    border: solid 2px $secondary;
  }

  .icon-container {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    border-radius: 4px;
    margin-right: 8px;
    padding: 8px;

    > img {
      width: auto;
      height: 100%;
    }

    &.morning {
      background-color: $activity-morning;
    }

    &.afternoon {
      background-color: $activity-afternoon;
    }
  }

  .infos {
    display: inline-block;
    height: 100%;
    line-height: normal;
    vertical-align: middle;

    .title {
      color: $secondary;
      font-size: 175%;
      font-weight: 600;
    }

    .schedule {
      height: 36.84%;
      font-size: 112.5%;

      > img {
        height: 100%;
      }

      > p {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
