


















@import "../../style/variables";

.screen-placeholder {
  p {
    color: $secondary;
    font-size: 500%;
    font-family: "Lobster", sans-serif;
    font-weight: normal;
    text-align: center;
    margin-top: 20%;
    margin-bottom: 0;
  }
}
