


































































































































































































































































@import "@/style/mixins";

.viewer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 150;
  display: flex;
  flex-flow: column;
  background-color: rgba(0, 0, 0, 0.85);

  .app-view {
    height: 100%;

    .carousel-container {
      height: 100%;

      .flicking-container {
        height: 100%;

        .image-container {
          height: 100%;
          width: 100%;

          .image {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .btn-close {
      position: absolute;
      top: 30px;
      left: 30px;

      @media (max-width: $sm), (max-width: $md) and (orientation: landscape) {
        width: 45px;
        height: 45px;
      }

      .img-close {
        width: 100%;
        height: 100%;
      }
    }

    .counter-box {
      min-width: 100px;
      position: absolute;
      top: 30px;
      right: 30px;
      padding: 9px 10px;
      border-radius: 8px;
      background-color: $background-primary;
      text-align: center;
      @include title-body();
    }

    .arrow {
      position: fixed;
      top: 50%;

      &.left {
        left: 40px;
        transform: translate(0, -50%);
      }

      &.right {
        right: 40px;
        transform: translate(0, -50%);
      }

      .btn-arrow {
        width: 50px;
        height: 50px;
      }
    }

    .btn-primary {
      width: 160px;
      position: fixed;
      right: 40px;
      bottom: 40px;
    }

    .btn-close,
    .counter-box,
    .arrow,
    .btn-primary {
      z-index: 100;
    }

    .counter-box,
    .arrow,
    .btn-primary {
      display: none;
    }

    &.overflow {
      .counter-box,
      .arrow,
      .btn-primary {
        display: block;
      }
    }
  }
}
