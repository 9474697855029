







































































































@import "@/style/variables";

.widget-list {
  padding: 28px 15px;

  @media (min-width: $md), (orientation: landscape) {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .widget {
    padding: 12px 0;
  }

  h1 {
    padding: 12px;
  }
}
