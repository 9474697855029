

























.default-view {
  height: 100%;
  display: flex;
  flex-flow: column;
}
