






















































































































































































































































@import "@/style/mixins";

.question {
  height: 100%;
  display: flex;
  flex-flow: column;

  .progress-block {
    margin: 32px 32px 0 32px;

    .progress-title {
      width: 100%;
      margin-top: 10px;
      text-align: right;
    }
  }

  .question-container {
    .form-container {
      width: 100%;
      padding: 0 40px;
      flex: 1;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .widget {
        padding: 24px;
      }
    }
  }

  .success-container {
    width: 100%;
    padding: 0 40px 88px 40px;

    .success-title {
      @include title-secondary-style();
    }

    .success-subtitle {
      color: $text-body;
      margin-top: 30px;
    }

    .btn-highlight {
      width: 215px;
      margin: 50px auto 0 auto;
    }
  }

  .loading-container {
    padding: 0 40px 88px 40px;

    .loading-title {
      margin-top: 25px;
      @include title-secondary-style();
    }
  }

  .loading-container,
  .success-container,
  .question-container {
    height: 100%;
    display: flex;
    overflow: scroll;
    flex-direction: column;
  }

  .button-container {
    padding: 32px;

    .btn-default,
    .btn-highlight {
      max-width: 300px;

      &.btn-prev {
        margin-right: 12px;
      }

      &.btn-next {
        margin-left: 12px;
      }
    }

    .btn-default {
      @media (max-width: $sm) {
        width: unset;
      }
    }
  }
}
