














































@import "../../style/variables";

.team-item.horizontal {
  padding: 2% 0;

  .team-photo {
    width: 25.56%;
    vertical-align: middle;
    border-radius: 50%;
  }

  .team-content {
    width: 70%;
    padding-left: 4%;
    vertical-align: middle;
    display: inline-block;

    .team-name {
      font-size: 300%;
      font-weight: 600;
      color: $secondary;
    }

    .team-role {
      font-size: 225%;
      color: $text-body;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.team-item.vertical {
  padding: 8% 0;
  text-align: center;

  .team-photo {
    width: 35.56%;
    vertical-align: top;
    border-radius: 50%;
  }

  .team-content {
    margin-top: 5%;

    .team-name {
      font-size: 300%;
      font-weight: 600;
      color: $secondary;
    }

    .team-role {
      font-size: 225%;
      color: $text-body;
    }

    p {
      margin-bottom: 0;
    }
  }
}
