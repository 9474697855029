


































































@import "@/style/mixins";

.form-text-field {
  text-align: left;

  .textfield {
    position: relative;

    .error {
      width: 100%;
      position: absolute;
      color: red;
      bottom: -25px;
      left: 5px;
    }
  }

  .btn-submit {
    padding-top: 24px;
  }

  .btn-highlight {
    height: 56px;
    @include title-body();
  }
}
