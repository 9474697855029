




































@import "../../style/variables";

.card-ephemeris {
  background-image: url("~@/assets/images/card-calendar.png");
  background-image: -webkit-image-set(url("~@/assets/images/card-calendar@2x.png") 2x, url("~@/assets/images/card-calendar@3x.png") 3x);
  background-image: image-set(url("~@/assets/images/card-calendar@2x.png") 2x, url("~@/assets/images/card-calendar@3x.png") 3x);
  background-position: bottom right;
  background-size: 23.7%;
  background-repeat: no-repeat;

  .content {
    width: 75%;
    font-size: 275%;
    color: $secondary;
    margin: 2% 0;
    display: inline-block;
  }

  .info-unavailable {
    margin: 5% 0;
    text-align: center;
    font-size: 250%;
    font-weight: 600;
    font-family: "Lobster", sans-serif;
    color: $secondary;
  }
}
