




















































































































































@import "@/style/variables";

.activities {
  height: 100%;
  display: flex;
  flex-flow: column;

  .description {
    padding: 0 40px;

    .week {
      max-width: 100px;
      min-width: 61px;
      margin: auto;
      padding: 12px;

      .week-date {
        color: $text-body;
        font-weight: 400;
      }

      &.current-date {
        background-color: $primary;
        border-radius: 26.7px;
        margin: 8px auto;

        .week-date {
          color: white;
        }

        .weekday {
          color: $background-default;
        }
      }
    }

    .activities-container {
      min-height: 65px;
    }

    .day-content {
      padding: 4px 4px 3px 4px;

      .widget-activity {
        margin: 8px;

        @media (max-width: $md) and (orientation: portrait) {
          width: 100%;
        }
      }
    }
  }
}
