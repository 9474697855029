


























































@import "../../style/_variables.scss";

.screen-card.menu-day-card {
  height: 100%;
  padding: 1.25%;

  .date {
    text-align: center;
    text-transform: capitalize;
    font-size: 187.5%;
    margin-bottom: 5%;

    .week-day {
      color: $secondary;
      font-size: 106.67%;
      font-weight: 600;
    }
  }

  .content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .card-menu-day-item {
      margin-bottom: 5%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .coming-soon-day {
      margin: 0;
      text-align: center;
      font-size: 250%;
      font-weight: 600;
      font-family: "Lobster", sans-serif;
      color: $secondary;
    }
  }

  .card-menu-day-item.special {
    background-color: #dfece9;

    .menu-item {
      color: #3c3c3c;
    }

    hr {
      border-color: $secondary;
    }
  }
}
