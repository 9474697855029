


































































@import "@/style/mixins";

.menu {
  width: 100%;
  height: 100%;
  padding: 30px;
  position: relative;

  .date {
    padding-bottom: 30px;
    @include title-secondary-style();
  }

  .empty-meals {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .title {
      @include title-secondary-style();
      font-size: 2rem;
      color: $disabled;
    }
  }

  .meal {
    width: 100%;
    margin-bottom: 17px;
    padding: 24px;

    .meal-title {
      text-align: center;
      margin-bottom: 5px;
    }

    .border {
      width: 100%;
      height: 2px;
      border: solid 1.5px #e8e8e8;
    }

    .special-meal {
      padding-top: 26px;
      padding-bottom: 8px;

      .special-meal-title {
        @include title-bold();
      }

      .special-meal-label {
        color: $primary;
        font-size: 1.875rem;
        @include title-secondary-style();
      }
    }

    .meal-content {
      width: 100%;
      padding: 15px;

      .meal-content-title {
        color: $text-body;
        font-weight: 400;
        text-align: center;
      }
    }
  }

  .complement {
    margin: 20px 0;
    text-align: center;

    .link {
      @include title-body();
      color: $primary;
    }
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
