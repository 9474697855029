


















































.forget-password {
  height: 100%;

  .back {
    position: absolute;
    top: 40px;
    left: 40px;
  }

  .content {
    max-width: 500px;
    padding: 49px 32px 32px 32px;
    margin: 32px;
    text-align: left;

    &.success-content {
      max-width: 500px;
      padding-top: 64px;

      .title {
        margin-bottom: 56px;
        text-align: center;
      }
    }
  }
}
