
















































































































































.profile {
  .content {
    padding: 20px;

    .widget {
      width: 100%;
      max-width: 550px;
      margin: 50px auto;

      .widget-content {
        padding: 30px 32px;

        .name {
          padding-bottom: 30px;
        }

        .sync-status {
          text-align: center;
          padding-bottom: 30px;
        }

        .btn-primary {
          margin-bottom: 24px;
        }
      }
    }

    .version {
      width: 100%;
      text-align: center;
    }
  }

  .fields {
    margin-bottom: 38px;
  }
}
