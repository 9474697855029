



























@import "@/style/variables";

.trombi {
  width: 100%;
  height: 100%;
  padding: 40px;

  @media (max-width: $sm), (max-width: $md) and (orientation: landscape) {
    padding: 20px;
  }

  .pdf-container {
    height: 100%;
    overflow: auto;
  }
}
