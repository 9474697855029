




















































































































































































@import "@/style/mixins";

.home-module {
  width: 100%;
  max-width: 600px;
  padding: 40px 15px 0 15px;

  .title {
    @include title-secondary-style();
    font-size: 3.25rem;
    text-align: left;
    padding-bottom: 48px;

    @media (max-width: $sm) {
      @include title();
      padding-bottom: 24px;
    }
  }

  .home-button {
    padding: 16px 0;
  }

  @media (min-width: $md), (orientation: landscape) {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: $md), (orientation: portrait) {
    .title {
      text-align: center;
    }
  }
}
