@import "variables";

.h-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba($color: #000000, $alpha: 0.8);

  &__spinner {
    position: relative;
    z-index: 2;
    width: 150px;
    height: 150px;
  }
}
