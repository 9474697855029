






























































































.change-password {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-view {
    padding: 20px;

    .content {
      max-width: 550px;
      padding: 30px 32px;

      h3 {
        margin-bottom: 30px;
      }
    }
  }
}
