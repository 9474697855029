














































@import "@/style/mixins";

.widget-place {
  width: 335px;
  margin-top: 10px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.32);
  background-color: white;

  .content {
    padding: 15px 20px;

    .title {
      text-align: left;
    }

    .sub-title {
      text-align: left;
      font-weight: normal;
      margin-bottom: 16px;
    }

    .description {
      @include body();
    }
  }

  .top-tail {
    position: absolute;
    left: 28px;
    top: -10px;
    height: 0px;
    width: 0px;
    margin-left: -10px;
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top-color: transparent;
    border-top-style: solid;
  }
}
