










































@import "@/style/variables";

.btn-home {
  height: 85px;
  position: relative;
  width: 100%;
  color: white;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 8px;
  background-color: $primary;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);

  .btn-container {
    width: 100%;
    height: 100%;

    .name {
      width: 100%;
      line-height: 1.2em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.btn-home-primary-dark {
    background-color: $secondary;
  }

  &.btn-home-white {
    background-color: white;
    color: $secondary;
  }

  &:disabled {
    opacity: 0.3;
    box-shadow: none;
  }
}

.badge {
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 1000;
  top: -12px;
  right: -12px;
  border-radius: 14px;
  background-color: $highlight;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
}
