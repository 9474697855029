





































@import "@/style/variables";

.message-container {
  .message-data {
    margin-bottom: 8px;

    &.me {
      text-align: right;
    }

    &.other {
      text-align: left;
    }
  }

  .message {
    max-width: 540px;
    padding: 16px;
    border-radius: 8px;
    color: $text-body;
    background-color: white;
    text-align: left;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);

    &.me {
      color: $secondary;
      background-color: $background-primary;
    }
  }
}
