




















































@import "../../style/_variables.scss";

.column {
  width: 49.8%;
  height: 100%;
  padding: 2.63%;
  display: inline-block;
  vertical-align: top;

  .full-height {
    height: 100%;

    .menu-item > p {
      margin-bottom: 6%;
    }

    &.lunch {
      background-image: url("~@/assets/images/lunch.png");
      background-image: -webkit-image-set(url("~@/assets/images/lunch@2x.png") 2x, url("~@/assets/images/lunch@3x.png") 3x);
      background-image: image-set(url("~@/assets/images/lunch@2x.png") 2x, url("~@/assets/images/lunch@3x.png") 3x);
      background-position: bottom right;
      background-size: 31.34%;
      background-repeat: no-repeat;

      &.special {
        background-color: #dfece9;
        background-image: url("~@/assets/images/lunch-special.png");
        background-image: -webkit-image-set(
          url("~@/assets/images/lunch-special@2x.png") 2x,
          url("~@/assets/images/lunch-special@3x.png") 3x
        );
        background-image: image-set(url("~@/assets/images/lunch-special@2x.png") 2x, url("~@/assets/images/lunch-special@3x.png") 3x);

        hr {
          border-color: $secondary;
        }

        .menu-item {
          color: #3c3c3c;
        }
      }
    }

    &.dinner {
      background-image: url("~@/assets/images/dinner.png");
      background-image: -webkit-image-set(url("~@/assets/images/dinner@2x.png") 2x, url("~@/assets/images/dinner@3x.png") 3x);
      background-image: image-set(url("~@/assets/images/dinner@2x.png") 2x, url("~@/assets/images/dinner@3x.png") 3x);
      background-position: bottom left;
      background-size: 33.46%;
      background-repeat: no-repeat;
    }

    .coming-soon {
      margin: 32% 0;
      font-size: 350%;
      font-weight: 600;
      font-family: "Lobster", sans-serif;
      color: $secondary;
      text-align: center;
    }
  }
}
