



































































@import "@/style/mixins";

.links {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-view {
    padding: 30px;

    .title {
      text-align: left;
      padding: 10px;
      @include title-secondary-style();
    }

    .widget {
      padding: 10px;
      width: 100%;
    }

    .border-link {
      width: 100%;
      padding: 20px 10px;
    }
  }
}
