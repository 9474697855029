













































































.shopping {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-view {
    padding-bottom: 40px;

    .product {
      position: relative;
      padding: 40px 40px 10px 40px;

      .product-container {
        padding: 15px;

        .product-photo-container {
          width: 100%;
          padding: 15px;
          max-width: 270px;

          .photo {
            width: 100%;
            object-fit: contain;
            border-radius: 8px;
          }
        }

        .description {
          padding: 15px;
        }
      }

      .product-link {
        width: 132px;
        padding: 12px;
        text-decoration: none;
        text-align: center;
        position: absolute;
        cursor: pointer;
        right: 60px;
        bottom: -10px;
      }
    }
  }
}
