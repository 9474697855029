













.widget {
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  background-color: white;
  max-height: 100%;
  overflow: auto;
}
