





















































.column-4 {
  display: flex;
  flex-direction: column;
  width: 23%;
  margin-left: 1%;
  margin-right: 1%;
}
