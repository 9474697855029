




































































































@import "@/style/mixins";

.login {
  height: 100%;

  .content {
    max-width: 500px;
    margin: 32px;
    padding: 0 32px 32px 32px;

    .logo {
      width: 230px;
      margin: 28px auto 35px auto;
      text-align: center;
      object-fit: contain;
    }

    .fields {
      padding-bottom: 16px;
    }

    .forget-password {
      @include body();
      text-align: center;
    }
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }
}
