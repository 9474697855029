
























@import "@/style/variables";

.widget-primary {
  .title-info {
    padding: 16px 20px 0 20px;

    .title {
      width: 100%;
      text-align: left;
      padding-bottom: 4px;
      border-bottom: 2px solid $border;
    }
  }
}
