




























































































































































































































@import "@/style/mixins";

.contact {
  height: 100%;
  display: flex;
  flex-flow: column;

  .chat-container {
    height: 100%;

    .chat-history {
      width: 100%;
      flex: 1;
      overflow-y: auto;
      padding: 40px;

      .message {
        padding: 20px 0;
      }

      .is-sent {
        @include title-h3();
        color: $text-body;
        text-align: right;
      }
    }
  }
}
