

































































@import "../../style/_variables.scss";

.screen-animations {
  .animations-row {
    height: 14%;
  }

  .separator {
    height: 2px;
    margin: 0 0 0 11.3%;
    background-image: url("~@/assets/images/separator-horizontal.png");
    background-repeat: repeat-x;
    background-size: auto 2px;
  }
}
