
























































































































































































































































@import "@/style/mixins";

.calendar {
  height: 100%;
  display: flex;
  flex-flow: column;

  .content {
    height: 100%;

    .calendar-container {
      flex: 1 1;
      min-width: 340px;
      max-width: 640px;
      margin: 48px 20px;

      @media (max-height: 520px) {
        display: none;
      }

      .weekly-button {
        display: grid;

        @media (max-height: 592px) {
          display: none;
        }

        .jda-btn {
          max-width: 415px;
          text-align: center;
          margin: 20px auto;
          font-weight: 600;
        }
      }

      .calendar-widget {
        width: 100%;
      }
    }

    @media (max-width: $md), (orientation: portrait) {
      .calendar-container {
        display: none;
      }
    }

    .information-container {
      height: 100%;
      overflow-y: auto;
      max-width: 640px;
      display: flex;
      flex-flow: column;
      flex: 2 1;
      padding: 48px 20px;

      .date {
        width: 100%;
        position: relative;
        padding: 0 32px;

        .arrow {
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          display: block;

          &.arrow-left {
            left: 0;
          }

          &.arrow-right {
            right: 0;
          }
        }
      }

      .calendar-day {
        margin-top: 20px;
        padding-bottom: 52px;

        &::-webkit-scrollbar {
          display: none;
        }

        .team {
          margin-top: 20px;
        }

        .program {
          width: 100%;
          padding: 12px 24px;
          margin-top: 20px;

          .block {
            margin-bottom: 30px;

            .title {
              text-align: left;
              padding-bottom: 4px;
              border-bottom: 2px solid $border;
              margin-bottom: 8px;
              color: $secondary;
            }

            .widget-activity {
              padding: 8px 24px 8px 0;
            }

            .meal {
              margin-bottom: 12px;
              line-height: 1.2;
              text-align: left;
              @include body();

              &:last-child {
                margin-bottom: 0;
              }

              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .loader-block {
    padding: 50px;
  }
}
