



















































@import "../../style/_variables.scss";

.animations-container {
  .date-container {
    display: inline-block;
    height: 75%;
    width: 11.3%;
    border-radius: 13px;
    padding: 0.5% 1%;
    margin: 0.8% 2.63% 0.8% 0;
    vertical-align: middle;

    > p {
      text-transform: capitalize;
      font-size: 170%;
      line-height: normal;
      letter-spacing: -0.39px;

      span {
        color: $secondary;
        font-weight: 600;
        font-size: 114.3%;
      }
    }

    &.today {
      background-color: $primary;
      box-shadow: 0 2px 14px 0 rgba(70, 150, 130, 0.24);

      &,
      > p span {
        color: white;
      }
    }
  }

  .activities-container {
    display: inline-block;
    overflow-x: hidden;
    white-space: nowrap;
    vertical-align: middle;
    width: 85%;
    height: 100%;
  }
}
