





















































































































































































































.restaurant {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-view {
    height: 100%;
    position: relative;

    .menu-block {
      width: 100%;
      height: 100%;
      max-width: 484px;

      &.scrollable {
        display: flex;
        flex-flow: column;
        overflow-y: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .border-menu,
    .arrow {
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;

      &.left {
        left: 0;
        transform: translateX(-50%);
      }

      &.right {
        right: 0;
        transform: translateX(50%);
      }
    }

    .border-menu {
      .border-margin {
        height: 90px;
        width: 5px;
      }
    }

    .arrow {
      z-index: 100;
    }
  }
}
