































































































































@import "@/style/mixins";

.date-pick {
  .header-month {
    text-align: center;

    .month {
      display: inline-flex;
      position: relative;
      margin-bottom: 10px;
      padding: 3px 0;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
      background-color: white;
      border-radius: 9.6px;

      select {
        @include title-h3();
        z-index: 10;
        padding: 0 33px 0 15px;
        font-weight: normal;
        border: none;
        color: $secondary;
        background-color: transparent;
        text-align-last: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      .img-dropdown {
        position: absolute;
        top: 10px;
        bottom: 10px;
        right: 15px;
        object-fit: contain;
      }
    }
  }

  .header-border {
    width: 100%;
    height: 2px;
    padding: 0 20px;

    .border-content {
      width: 100%;
      height: 100%;
      background-color: $border;
    }
  }

  .weekday {
    padding-top: 19px;
    padding-bottom: 25px;
    @include title-body();
    text-align: center;
  }

  .btn-date {
    width: 61px;
    height: 65px;
    margin: 0 5px;
    border-radius: 28px;
    @include body2();
    color: $disabled;

    @media (max-width: $xl) {
      width: 37px;
      height: 41px;
    }

    &.btn-current-month {
      color: $text-body;
    }

    &.btn-current {
      color: $danger;
    }

    &.btn-selected {
      color: white;
      font-weight: bold;
      background-color: $primary;
      box-shadow: 0 2px 14px 0 rgba(70, 150, 130, 0.24);
    }
  }
}
