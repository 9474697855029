


























@import "../../style/_variables.scss";

.screen-card .card-menu-day-item {
  position: relative;
  border: solid 1px #dfece9;
  border-radius: 8px;
  padding: 2.5%;

  hr {
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
    border-top: 1px solid #e8e8e8;
  }

  .title {
    text-align: center;
    margin: 0;
    color: $secondary;
    font-size: 187.5%;
    font-weight: 600;
  }

  .subtitle {
    text-align: center;
    color: $secondary;
    font-size: 125%;
    margin-top: 0.4%;
    margin-bottom: 0;
  }

  .coming-soon {
    margin: 15% 0;
    text-align: center;
    font-size: 250%;
    font-weight: 600;
    font-family: "Lobster", sans-serif;
    color: $secondary;
  }
}
