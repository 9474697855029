
































































@import "@/style/variables";

.widget-event {
  .widget-content {
    width: 100%;
    padding: 15px 20px 24px 20px;

    .btn-content {
      width: 100%;
      padding: 0;
      border-radius: 8px;
    }

    .title {
      padding: 10px;
      border-radius: 8px;
    }
  }

  &:nth-child(even) {
    .widget-content {
      .title {
        background-color: $activity-morning;
      }
    }
  }

  &:nth-child(odd) {
    .widget-content {
      .title {
        background-color: $activity-afternoon;
      }
    }
  }
}
