


































@import "@/style/mixins";

.message-box {
  background-color: $primary;
  padding: 40px 40px 24px 40px;
  z-index: 1;
  position: relative;

  .message-field {
    width: 100%;
    padding-right: 16px;

    .message {
      @include title-h3();
      min-height: 94px;
      padding: 12px 16px;
      font-weight: normal;
      color: $text-body;
      border-radius: 8px;
    }
  }

  @media (max-width: $sm), (max-width: $md) and (orientation: landscape) {
    padding: 16px 16px 0 16px;

    .message-field .message {
      @include title-section();
      font-weight: normal;
    }
  }

  .btn-highlight {
    width: auto;
  }
}
