




















































































@import "@/style/variables";

.list-photo {
  height: 100%;
  display: flex;
  flex-flow: column;

  .title {
    padding-top: 30px;

    .date {
      color: $text-body;
    }

    .content-right {
      padding-top: 20px;

      @media (min-width: 768px) {
        padding-top: 0;
        text-align: right;
      }

      .count {
        display: inline;
        padding: 12px 16px;
        border-radius: 8px;
        background-color: #eaf1ef;
      }
    }
  }

  .images {
    text-align: center;
    padding: 15px;

    .btn-widget-image {
      width: 218px;
      margin: 15px;

      @media (min-width: $xl) {
        width: 256px;
      }
    }
  }
}
