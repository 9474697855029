












































@import "../../style/variables";

.card-team {
  background-position: bottom right;
  background-size: 33.46%;
  background-repeat: no-repeat;

  &.day-night {
    background-image: url("~@/assets/images/day-night.png");
    background-image: -webkit-image-set(url("~@/assets/images/day-night@2x.png") 2x, url("~@/assets/images/day-night@3x.png") 3x);
    background-image: image-set(url("~@/assets/images/day-night@2x.png") 2x, url("~@/assets/images/day-night@3x.png") 3x);
  }

  &.day {
    background-image: url("~@/assets/images/day-shape.png");
    background-image: -webkit-image-set(url("~@/assets/images/day-shape@2x.png") 2x, url("~@/assets/images/day-shape@3x.png") 3x);
    background-image: image-set(url("~@/assets/images/day-shape@2x.png") 2x, url("~@/assets/images/day-shape@3x.png") 3x);
  }

  &.night {
    background-image: url("~@/assets/images/night-shape.png");
    background-image: -webkit-image-set(url("~@/assets/images/night-shape@2x.png") 2x, url("~@/assets/images/night-shape@3x.png") 3x);
    background-image: image-set(url("~@/assets/images/night-shape@2x.png") 2x, url("~@/assets/images/night-shape@3x.png") 3x);
  }

  .content {
    width: 75%;
    font-size: 275%;
    color: $secondary;
    display: inline-block;
  }

  .divider {
    width: 22.9%;
    margin: 8% auto;
    display: block;
  }

  .info-unavailable {
    margin: 36% 0;
    text-align: center;
    font-size: 250%;
    font-weight: 600;
    font-family: "Lobster", sans-serif;
    color: $secondary;
  }
}
