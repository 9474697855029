


























































@import "@/style/variables";

.team-member-block {
  .photo {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    border: solid 1px $border;
    object-fit: cover;
  }

  .content {
    padding-left: 8px;
    padding-right: 8px;

    .name {
      display: inline;
      margin-right: 8px;
    }

    .job {
      color: $text-body;
      font-weight: normal;
    }

    .name,
    .job {
      text-align: left;
    }

    .team-icon {
      width: 20px;
      height: 20px;
    }
  }
}
