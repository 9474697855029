
























@import "@/style/variables";

.widget-secondary {
  .title-info {
    width: 100%;
    padding: 24px;
    border-top-left-radius: 8px 8px;
    border-top-right-radius: 8px 8px;
    background-color: rgba(199, 223, 217, 0.5);

    .title {
      color: $secondary;
      text-align: left;
    }
  }
}
