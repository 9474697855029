

















































































































@import "@/style/variables";

.albums {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-view {
    padding: 30px;
  }

  .new-album {
    margin-bottom: 30px;
    padding: 10px 0;
    border-radius: 12px;
    background-color: $background-primary;

    .new-title {
      padding: 0 20px 10px 20px;
      font-family: "Lobster", sans-serif;
    }

    .new-album-container {
      text-align: center;
      overflow-x: auto;
      white-space: nowrap;

      .album {
        margin: 0 20px;
        display: inline-block;
      }
    }
  }

  .albums-container {
    .year {
      text-align: left;
      padding-bottom: 5px;
      font-family: "Lobster", sans-serif;
    }

    .album {
      max-width: 321px;

      @media (max-width: $md) {
        max-width: none;

        .album-group {
          margin: 0 auto;
        }
      }
    }

    .border-horizontal {
      margin: 20px 0;
    }

    &:last-child {
      .border-horizontal {
        display: none;
      }
    }
  }
}
