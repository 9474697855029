















@import "@/style/variables";

.progress-bar {
  width: 100%;
  height: 20px;
  position: relative;
  border-radius: 10px;
  background-color: $activity-morning;

  .progress {
    height: 20px;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $secondary;
  }
}
