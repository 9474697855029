button {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}

.jda-btn {
  @include title-body();
  border: none;

  &.btn-default {
    background-color: $background-default;
  }

  &.btn-primary {
    color: white;
    background-color: $primary;
  }

  &.btn-secondary {
    color: white;
    background-color: $secondary;
  }

  &.btn-highlight {
    background-color: $highlight;
  }

  &.btn-danger {
    color: $danger;
    background-color: $background-danger;
    border-color: $background-danger;
  }

  &.btn-primary,
  &.btn-secondary,
  &.btn-highlight,
  &.btn-danger,
  &.btn-default {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);

    &:hover {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
