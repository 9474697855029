































.widget-link {
  text-decoration: none;
  cursor: pointer;

  .link-container {
    height: 70px;
    padding: 14px 16px;

    .title {
      display: block;
      display: -webkit-box;
      padding-right: 10px;
      text-align: left;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn-link {
      width: 40px;
      height: 40px;
      padding: 0;
    }
  }
}
